/* src/App.css */
.App {
  text-align: center;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

section {
  margin: 20px;
}

footer {
  background-color: #282c34;
  padding: 10px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}